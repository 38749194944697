$(function () {
    window.Geenie.Watched = {

        cookie_name: 'watched',
        get_promise: function (params) {
            settings = $.extend({
                type: 'get',
                url: '/api/watched',
                dataType : 'json',
                cache: false,
            }, params);
            return $.ajax(settings);
        },
        set_cookie: function () {
            var key = this.cookie_name;
            var current_project_id = $('#project_id').val();
            if(!current_project_id){
                return;
            }
            var max_project_length = 20;
            var options = {path: '/', expires: 7};
            var cookies = $.cookie(key);
            if (!cookies || cookies == 'undefined') {
                $.cookie(key, current_project_id, options);
            } else {
                cookies = cookies.split(",");
                if (cookies.length > 0) {
                    for (var i=0; i<cookies.length; i++) {
                        var cookie = cookies[i];
                        if (current_project_id == cookie) {
                            cookies.splice(i, 1);
                        }
                    }
                    cookies.unshift(current_project_id);
                    if (cookies.length > max_project_length) {
                        cookies.pop();
                    }
                    $.cookie(key, cookies.join(","), options);
                } else {
                    $.cookie(key, current_project_id, options);
                }
            }
        },
        get_cookie: function () {
            var parts = $.cookie(this.cookie_name);
            if (parts) {
                return parts.split(",").slice();
            }
            return null;
        }
    };
});
